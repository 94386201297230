
import { defineComponent } from 'vue'
import moment from 'moment'

moment.locale('zh-tw')

export default defineComponent({
  props: ['propRange', 'propMaxDate', 'propMinDate'],
  data() {
    return {
      masks: {
        inputDateTime24hr: 'YYYY-MM-DD HH:mm'
      },
      popover: { visibility: 'click' },
      range: {
        start: this.propRange.start,
        end: this.propRange.end
      },
      maxDate: this.propMaxDate,
      minDate: this.propMinDate
    }
  },
  watch: {
    range(newVal, oldVal) {
      this.$emit('update', newVal)
    }
  },
  methods: {
    dayClick(val: any) {
      this.maxDate = moment(val.id).add(1, 'month').toDate()
      this.minDate = moment(val.id).subtract(1, 'month').toDate()
    }
  }
})
