import axios from '@/http'

export default function exportExcel(
  exportType: string,
  storeId: string,
  payload: unknown | any,
  modal: unknown | any,
  notify: unknown | any,
  router: unknown | any
) {
  return axios
    .get(`/export/${storeId}/${exportType}`, {
      params: payload
    })
    .then((res) => {
      const confirmModal = modal({
        content:
          '報表需求已送出，可前往「歷程記錄」</br>' +
          '了解當前處理狀況，待資料處理完畢 </br>' +
          '，即可檢視或下載檔案，謝謝。',
        confirmBtnText: '歷程紀錄',
        classType: 'success',
        confirmBtnClick: () => {
          return new Promise((resolve, reject) => {
            router
              .push({
                name: 'history'
              })
              .then(() => {
                resolve(true)
              })
          })
        }
      })

      notify(
        {
          classType: 'success',
          message: '已送出匯出作業',
          closeAble: true
        },
        5000
      )
      confirmModal.show()
    })
    .catch((err) => {
      notify(
        {
          classType: 'error',
          message: `匯出失敗，${err.response.errors.detail}`,
          closeAble: true
        },
        60000
      )
    })
}
