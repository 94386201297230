
import { defineComponent, ref, reactive, inject, computed, toRef } from 'vue'
import {
  device,
  employee,
  modalInstance,
  notifyInstance,
  orderRecord
} from '@/type'
import axios from '@/http'
import { useStore } from 'vuex'
import DateRangePicker from '@/views/components/dateRangePicker.vue'
import moment from 'moment'
import usePagination from '@/plugins/usePagination'
import { useRoute, useRouter } from 'vue-router'
import exportExcel from '@/plugins/exportExcel'
import helper from '@/helper'

moment.locale('zh-tw')

interface pagination {
  getData: () => void
  go: (page: number) => void
  next: () => void
  pre: () => void
}

export default defineComponent({
  components: {
    DateRangePicker
  },
  setup() {
    const notify = inject('notify') as notifyInstance
    const modal = inject('modal') as modalInstance

    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const nowStore = computed(() => store.getters.nowStore)
    const storeId = computed(() => nowStore.value.id)

    const globalVariable = computed(() => store.getters.globalVariable)
    const paymentsMap = computed(() => globalVariable.value.payments)
    const cardTypesMap = computed(() => globalVariable.value.cardTypes)
    const discountsMap = computed(() => globalVariable.value.discounts)
    const fromMap = computed(() => globalVariable.value.from)
    const orderStatusMap = computed(() => globalVariable.value.orderStatus)

    const exportType = ref('Pre')

    const employees = ref(new Array<employee>())
    const devices = ref(new Array<device>())
    let pagination: {
      next: () => any
      pre: () => any
      go: (goPage: number) => any
      getData: () => any
    } | null = null

    const paginationData = reactive({
      page: 1,
      pageSize: 10,
      lastPage: 1,
      total: 0,
      preOrderRecords: ref(new Array<any>())
    })

    const statistics = reactive({
      totalReceived: 0,
      totalChange: 0
    })

    const range = reactive({
      start: moment().startOf('day').format('YYYY-MM-DDTHH:mm'),
      end: moment().endOf('day').format('YYYY-MM-DDTHH:mm')
    })

    const paginationSetting = reactive({
      page: toRef(paginationData, 'page'),
      pageSize: toRef(paginationData, 'pageSize'),
      api: `/orders/pre/${storeId.value}`,
      payload: {
        startTime: toRef(range, 'start'),
        endTime: toRef(range, 'end')
      }
    })

    function fetchStatistics() {
      axios
        .get(`/orders/pre/${storeId.value}/statistics`, {
          params: {
            startTime: range.start,
            endTime: range.end
          }
        })
        .then((res) => {
          const data = res.data
          statistics.totalReceived = Number(data.totalReceived)
          statistics.totalChange = Number(data.totalChange)
        })
        .catch((err) => {
          notify(
            {
              classType: 'error',
              message: `資訊載入失敗，${err.response.errors.detail}`,
              closeAble: true
            },
            60000
          )
        })
    }

    function init() {
      const initEmployee = axios
        .get(`/employees/${storeId.value}`)
        .then((res) => {
          const data = res.data
          employees.value = data.employees
        })
        .catch((err) => {
          notify(
            {
              classType: 'error',
              message: `資訊載入失敗，${err.response.errors.detail}`,
              closeAble: true
            },
            60000
          )
        })
      const initDevice = axios
        .get(`/devices/${storeId.value}`)
        .then((res) => {
          const data = res.data
          devices.value = data.devices
        })
        .catch((err) => {
          notify(
            {
              classType: 'error',
              message: `資訊載入失敗 ${err.response.errors.detail}`,
              closeAble: false
            },
            3000
          )
        })
      const initPagination = usePagination(paginationSetting).then((res) => {
        pagination = res
      })

      Promise.all([initEmployee, initDevice, initPagination]).then(function () {
        fetchStatistics()
        getData()
      })
    }

    function getEmployeeName(employeeNo: string) {
      const employee = employees.value.find((employee: employee) => {
        return employee.no === employeeNo
      })
      if (employee !== undefined) {
        return employee.name
      }
      return ''
    }

    function getDeviceName(deviceId: number) {
      const device = devices.value.find((device: device) => {
        return parseInt(device.id) === deviceId
      })
      if (device !== undefined) {
        return device.name
      }
      return ''
    }

    function updateRange(updateRange: any) {
      range.start = moment(updateRange.start).format('YYYY-MM-DDTHH:mm')
      range.end = moment(updateRange.end).format('YYYY-MM-DDTHH:mm')
      fetchStatistics()
      goPage(1)
    }

    init()

    function nextPage() {
      const fetchData = pagination?.next()
      fetchData.then(() => {
        getData()
      })
    }

    function prePage() {
      const fetchData = pagination?.pre()
      fetchData.then(() => {
        getData()
      })
    }

    function goPage(page: number) {
      const fetchData = pagination?.go(page)
      fetchData.then(() => {
        getData()
      })
    }

    function getData() {
      const data = pagination?.getData()
      paginationData.page = data.currentPage
      paginationData.lastPage = data.lastPage
      paginationData.pageSize = data.pageSize
      paginationData.total = data.total
      paginationData.preOrderRecords = data.items.map(function (
        value: any,
        index: any
      ) {
        return Object.assign(
          {},
          {
            createdAt: value.createdAt,
            no: value.no,
            invoiceNo: value.invoiceNo,
            paymentLabel: value.paymentLabel,
            payment: value.payment,
            receivable: Number(value.receivable),
            moneyReceived: Number(value.moneyReceived),
            makeChange: Number(value.makeChange),
            creditCard: value.creditCard,
            creditCardCode: value.creditCardCode,
            employeeNo: value.employeeNo,
            deviceId: value.deviceId,
            approvalCode: value.approvalCode,
            referenceNo: value.referenceNo
          }
        )
      })
    }

    function formatNumber(number: any) {
      return number === undefined ? 0 : parseInt(number).toLocaleString('en-US')
    }

    function formatDateTime(time: any) {
      return moment(time).format('YYYY-MM-DD HH:mm:ss')
    }

    function exportExcelClick() {
      if (
        !helper.isAdmin() &&
        !helper.isStoreAdmin() &&
        !helper.hasExportPermission(route.meta.permission as string)
      ) {
        modal({
          content: '權限不足</br>' + '請與商店管理者確認權限</br>',
          confirmBtnText: '確定',
          classType: 'alert',
          cancelBtn: false
        }).show()
      } else {
        exportExcel(
          exportType.value,
          storeId.value,
          {
            startTime: range.start,
            endTime: range.end
          },
          modal,
          notify,
          router
        )
      }
    }

    return {
      formatNumber,
      formatDateTime,
      range,
      updateRange,
      paymentsMap,
      cardTypesMap,
      discountsMap,
      orderStatusMap,
      fromMap,
      paginationData,
      prePage,
      nextPage,
      goPage,
      getEmployeeName,
      getDeviceName,
      statistics,
      exportExcelClick
    }
  }
})
